import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {get, post} from '../../lib/axios'
import {connect} from "react-redux";

function CreateNode({is_open, selected_map_id, close, set, type, target, position}) {
    const [label, setLabel] = useState('');
    const [content, setContent] = useState('https://');
    const handleSubmit = () => {
        let data = {
            label: label,
            node_label: label,
            url: content,
            content_type: 'URL',
            parent:null,
            child:null,
            x:position.x,
            y:position.y
        };
        let url;
        switch(type){
            case 'ROOT':
                url = `/api/v1/seomalmap/${selected_map_id}/node/?type=root`;
                break;
            case 'PARENT':
                data.child = target
                url = `/api/v1/seomalmap/${selected_map_id}/node/?type=parent`;
                break;
            case 'CHILD':
                data.parent = target;
                url = `/api/v1/seomalmap/${selected_map_id}/node/?type=child`;
                break;
            default:
                break;
        }
        post(url, data, function (result) {
            close();
            get(`/api/v1/seomalmap/${selected_map_id}/`, {}, (json) => {
                set(json.data.elements, Number(selected_map_id, {read_public: json.data.read_public, write_public: json.data.write_public}))
            });
            setLabel('');
            setContent('');
        });
    };

    const isValidContent = (content) => {
        const isURL = (content) => {
            const url_pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
            return url_pattern.test(content);
        };
        if (content.length > 0) {
            if (isURL(content)) {
                return [true, null];
            } else {
                return [false, 'URL 형식이 올바르지 않습니다. ex)https://seomal.com'];
            }
        } else {
            return [false, '값이 없습니다.']
        }
    };
    const contentValidResult = isValidContent(content);

    const isValidLabel = (label) => {
        if (label.length > 0) {
            return [true, null]
        } else {
            return [false, '값이 없습니다']
        }
    };
    const labelValidResult = isValidLabel(label);

    return (
        <Dialog open={is_open} onClose={close} style={{zIndex:10000}}>
            <DialogTitle>Create Node</DialogTitle>
            <DialogContent style={{minWidth:'310px'}}>
                <DialogContentText>
                    노드 만들기 도움말
                </DialogContentText>
                <div><TextField label="name" error={!labelValidResult[0]} helperText={labelValidResult[1]} value={label} fullWidth
                                onChange={(e) => setLabel(e.target.value)}></TextField></div>
                <div><TextField label="url" error={!contentValidResult[0]} value={content}
                                onChange={(e) => setContent(e.target.value)}
                                helperText={contentValidResult[1]} fullWidth></TextField>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    (state, ownProps) => {
        return {
            is_open: state.getIn(['ui', 'create_node_is_open']),
            selected_map_id: state.getIn(['map', 'selected_map_id']),
            type:state.getIn(['ui','create_node_connect_type']),
            target:state.getIn(['ui','create_node_target']),
            position:state.getIn(['ui', 'cursor_last_tab_position'])
        }
    },
    (dispatch) => {
        return {
            close: () => dispatch({type: 'CLOSE_CREATE_NODE'}),
            set: (value, map_id, permission) => {
                dispatch({type: 'SET_MAP', value: value, selected_map_id: map_id, permission: permission})
            }
        }
    }
)(CreateNode)
