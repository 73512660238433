const textOutlineColor = 'white';
export const fontColor = 'black';
export const nodeBGColor = '#4f5b66';
export const edgeBGColor = '#c0c5ce';
export const nodeMinSize = 10;
export const fontMaxSize = 20;
export const fontMinSize = 12;
export const dimColor = '#f4f4f8';
export const edgeWidth = '0.6px';
export const arrowScale = 0.5
export const arrowActiveScale = 0.5
export const successorColor = 'rgb(246, 176, 172)';
export const predecessorsColor = 'rgb(140, 232, 250)';
export const nodeActiveBGColor = '#fed766';
export const nodeActiveSize = 23;
export const nodeActiveFontSize = 7;
export const edgeActiveWidth = '1px';
export const stylesheet = [ // the stylesheet for the graph
    {
        selector: 'node',
        style: {
            'font-family': 'Open Sans Condensed',
            'font-weight': '200',
            'label': 'data(label)',
            'text-valign': 'top',
            'color': fontColor,
            'text-outline-width': 0,
            'text-outline-color': textOutlineColor,
            'background-color': nodeBGColor
        }
    },
    {
        selector: 'edge',
        style: {
            'curve-style': 'bezier',
            'width': 1,
            'target-arrow-shape': 'triangle',
            'line-color': edgeBGColor,
            'target-arrow-color': edgeBGColor,
        }
    },
    {
        selector: '.prepare',
        style: {
            'opacity': '0.5'
        }
    }
]; // const successorWeakColor = '#ff8b94';
