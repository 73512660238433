import store from "../../../redux/store";
export const isMapMember = () => {
    const user = store.getState().getIn(['user']);
    const members = store.getState().getIn(['map', 'members']);
    if(!user.is_login){
        return false;
    }
    if(!members){
        return false;
    }
    if(members.length ===0){
        return false;
    }      
    const isMember = members.filter(e=>{
        return e.id === user.userid;
    }).length === 0 ? false : true;  
    return isMember;
}