import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";

const LastVisit = (props) => {
    const [open, setOpen] = React.useState(props.isOpen);

    useEffect(()=>{
        setOpen(props.isOpen);
    }, [props.isOpen]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={<a style={{color:'white', textDecoration:'none'}} href={`/map/${props.id}`}>마지막 방문 지도 : {props.name}</a>}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}
const mapStateToProps = (state)=>{
    return {
        isOpen:state.getIn(['ui', 'lastVisit', 'is_open']),
        id:state.getIn(['ui', 'lastVisit', 'id']),
        name:state.getIn(['ui', 'lastVisit', 'name'])
    }
}
const WrapReduxLastVisit = connect(mapStateToProps)(LastVisit);
export default WrapReduxLastVisit;