export const connect_source = (node) => {
    return {
        type:'CONNECT_EDGE',
        open:true,
        source:node
    }
}

export const connect_target = (node) => {
    return {
        type:'CONNECT_TARGET',
        open:false,
        target:node
    }
}

export const connect_abort = () => {return {type:'CONNECT_ABORT'}}

export const open_login_dialog = () => {return {type:'OPEN_LOGIN_DIALOG'}}

export const open_snackbar_dialog = (msg, duration) => {return {type:'OPEN_SNACKBAR_DIALOG', message:msg, duration:duration}}

export const close_snackbar_dialog = () => {return {type:'CLOSE_SNACKBAR_DIALOG'}}

export const open_create_node = (connect_type, target, position) => {
    return {
        type: 'OPEN_CREATE_NODE',
        connect_type: connect_type,
        target,
        position:position
    }
}

export const open_edit_node = (target, position) => {
    return {
        type: 'OPEN_EDIT_NODE',
        target,
        position:position
    }
}

export const select = (id, is_need_animation=false) => {
    return {
        type:'SELECT_NODE',
        selected_node:{
            id:id,
            is_need_animation:is_need_animation
        }
    }
};

export const close_tooltip = () => {
    return {type:'CLOSE_TOOLTIP'}
}

export const open_tooltip = (id, url, label, pos, countMarking) =>{
    return {type:'OPEN_TOOLTIP', id, url, label, pos, countMarking};
}

export const up_saw = (id) => {
    return {type:'UP_SAW', id}
}

export const set_user_marking = (marked) => {
    return {type:'SET_USER_MARKING', marked:marked}
}