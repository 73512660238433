import React from 'react';
import {connect} from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {connect_abort} from "../../redux/map.action";

export default connect(
    state => ({open: state.getIn(['ui', 'connect_edge', 'open'])}),
    dispatch => ({close:()=>{
        dispatch(connect_abort())
    }})
)(props => {
    return (
        <Snackbar open={props.open}>
        <Alert severity="success"  onClose={props.close} >
          부모가 될 지식을 선택해주세요
        </Alert>
      </Snackbar>
    );
})