import React from 'react';
import {connect} from 'react-redux';
import Drawer from "@material-ui/core/Drawer";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {makeStyles} from "@material-ui/core/styles";
import Search from './Search'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {select} from "../../redux/map.action";

const DRAWER_WIDTH = 300;
const DRAWER_SEARCH_SPACER = 120;

const useStyles = makeStyles({
    opener: {
        top: '10px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: 'none',
        display: 'inline-block',
        cursor: 'pointer',
        padding: '14px 0px 19px 1px',
        position: 'absolute',
        zIndex: 2000,
        height: '16px',
        backgroundColor: 'white'
    },
    openerIcon: {
        padding: 0
    },
    list: {
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
        boxShadow: '10px 10px 60px -39px rgba(0, 0, 0, 0.86)',
        backgroundColor:'rgba(255,255,255,0.8)',
        overflowY:'hidden'
    },
    drawerSearchSpacer:{},
    drawerPaperSearch: {
        width:DRAWER_WIDTH-18,
        margin: '0 10px 10px 10px',
        paddingTop:10,
        zIndex:1000,
        position:'fixed',
        backgroundColor:'white'
    }
});

function useClientRect() {
    const [rect, setRect] = React.useState(null);
    const ref = React.useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect, ref];
}


export const MenuMap = connect(
    (state) => {
        return {
            map_sub_drawer_is_open: state.getIn(['ui', 'map_sub_drawer_is_open']),
            elements:state.getIn(['map', 'elements']),
            selected_node:state.getIn(['map', 'selected_node'])
        }
    },
    (dispatch) => {
        return {
            closeDrawer: () => {
                dispatch({type: 'CLOSE_MAP_SUB_DRAWER'})
            },
            openDrawer: () => {
                dispatch({type: 'OPEN_MAP_SUB_DRAWER'})
            },
            select: (id, is_need_animation) => {
                dispatch(select(id, is_need_animation));
            }
        }
    }
)((props) => {
    const classes = useStyles();
    const [rect, ref] = useClientRect();
    const [refDrawer] = useClientRect();
    const getLeft = () => {
        if (rect === null)
            return 0;
        if (props.map_sub_drawer_is_open) {
            return (rect.width) + 'px';
        } else {
            return 0;
        }
    };
    const onOffHandler = () => {
        if (props.map_sub_drawer_is_open) {
            props.closeDrawer();
        } else {
            props.openDrawer();
        }
    };
    // ModalProps={{disableBackdropClick:true,BackdropProps:{invisible:true}}}
    // const fabIcon = props.map_sub_drawer_is_open ? <CloseIcon></CloseIcon> : <MenuIcon></MenuIcon>;
    const elementsList = props.elements.filter(e=> e.data.source ? false : true).map((e)=>{
        const active = props.selected_node.id === e.data.id;
        return <ListItem button key={e.data.id} selected={active} autoFocus={active} onClick={
                        ()=>{
                            props.select(e.data.id, true);
                        }
                    }>
                    <ListItemText primary={e.data.label} />
                </ListItem>
    });


    return (
        <div>
            <div style={{left: getLeft()}} className={classes.opener} onClick={onOffHandler}>
                {props.map_sub_drawer_is_open ? <ArrowLeftIcon className={classes.openerIcon}/> :
                    <ArrowRightIcon className={classes.openerIcon}/>}
            </div>
            <Drawer
                classes={{paper: classes.drawerPaper}}
                transitionDuration={0}
                className="MapSubDrawer"
                variant="persistent"
                open={props.map_sub_drawer_is_open}
                ref={refDrawer}>
                {/*drawer의 width를 알아내기 위해서 만든 엘리먼트*/}
                <div style={{width: '100%'}} ref={ref}></div>
                <div className={classes.drawerPaperSearch}>
                    <Search></Search>
                </div>
                <div style={{display:'grid', gridTemplateRows:DRAWER_SEARCH_SPACER+'px 1fr', height:'100vh'}}>
                    <div className={classes.drawerSearchSpacer}></div>
                    <div style={{overflowY:'scroll', borderTop:'1px solid rgba(0,0,0,0.12)'}}>
                        <List className={classes.list}>
                            {elementsList}
                        </List>
                    </div>
                </div>
            </Drawer>
        </div>
    )
});