import axios from "axios";
import {apiHost} from './axios';

const post = (url, data, success, failFn) => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencode',
    },
  };
  const bodyFormData = new FormData();
  let name;
  for(name in data){
    bodyFormData.set(name, data[name]);
  }
  return axios.post(apiHost + url, bodyFormData, config);
};

export const jwtStorageKeyName = 'JWT_TOKEN';

export const verifyJwtToken = async (jwtToken) => {
  if (!jwtToken) {
    return false;
  }
  try {
    const verify = await post('/api/token/verify/', {token: jwtToken});
    const token = verify.data.token;
    if (token) {
      saveJwtToken(token);
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const refreshToken = async (jwtToken) => {
  if (!jwtToken) {
    return false;
  }
  try {
    const refresh = await post('/api/token/refresh/', {token: jwtToken});
    const token = refresh.data.token;
    saveJwtToken(token);
    return token;
  } catch (e) {
    return false;
  }
};


export const getJwtToken = async() => {
  const jwtToken = localStorage.getItem(jwtStorageKeyName);
  try {
    const [token, ts] = JSON.parse(jwtToken);
    if (!token) {
      removeJwtToken();
      return false;
    }
    // 1분 이상 경과할 경우에만 토큰 검증
    if (new Date().getTime() - ts > 60000) {
      const verify = await verifyJwtToken(token);
      if (!verify) {
        removeJwtToken();
        return false;
      }
    }


    return token
  } catch (e) {
    removeJwtToken();
    return false;
  }

};

export const removeJwtToken = () => {
  localStorage.removeItem(jwtStorageKeyName);
};

export const parseJwt = (jwtToken) => {
  const base64Url = jwtToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


export const saveJwtToken = (jwtToken) => {
  if (!jwtToken) {
    localStorage.removeItem(jwtStorageKeyName);
    return false;
  }

  localStorage.setItem(jwtStorageKeyName, JSON.stringify([jwtToken, new Date().getTime()]));
  return true;
};
