import {
    arrowActiveScale,
    arrowScale,
    dimColor,
    edgeActiveWidth,
    edgeBGColor,
    edgeWidth,
    fontColor,
    fontMaxSize,
    fontMinSize,
    nodeActiveBGColor,
    nodeActiveFontSize,
    nodeActiveSize,
    nodeBGColor,
    nodeMaxSize,
    nodeMinSize,
    predecessorsColor,
    successorColor
} from "./style";
import {_delete, get, patch} from "../../../lib/axios";
import tinycolor from "tinycolor2";
import store from "../../../redux/store";
import isBeta from "../../../lib/beta";

function is_my_map() {
    let is_my_map = false;
    let userid = store.getState().getIn(['user', 'userid']);
    let members = store.getState().getIn(['map', 'members']);
    if (userid && members) {
        is_my_map = (store.getState().getIn(['map', 'members']).filter(item => item.id === userid)).length > 0
    }
    return is_my_map;
}

function is_login() {
    return store.getState().getIn(['user', 'is_login'])
}

function is_public_write_map() {
    return store.getState().getIn(['map', 'permission']).write_public;
}

function getMaxZIndex() {
    if (!window.zindex) {
        window.zindex = 1;
    }
    return ++window.zindex;
}

function setOpacityElement(target_element, degree) {
    target_element.style('opacity', degree);
}

function setFocus(target_cy, target_element, successorsColor, predecessorsColor, edgeWidth, arrowScale) {
    target_element.style('background-color', nodeActiveBGColor);
    target_element.style('color', fontColor);
    target_element.successors().each(
        function (e) {
            if (e.isEdge()) {
                e.style('width', edgeWidth);
                e.style('arrow-scale', arrowScale);
            }
            e.style('color', fontColor);
            e.style('background-color', successorColor);
            e.style('line-color', successorColor);
            e.style('target-arrow-color', successorColor);
            e.style('z-index', getMaxZIndex());
            setOpacityElement(e, 1);
        }
    );
    target_element.predecessors().each(function (e) {
        if (e.isEdge()) {
            e.style('width', edgeWidth);
            e.style('arrow-scale', arrowScale);
        }
        e.style('color', fontColor);
        e.style('background-color', predecessorsColor);
        e.style('line-color', predecessorsColor);
        e.style('target-arrow-color', predecessorsColor);
        e.style('z-index', getMaxZIndex());
        setOpacityElement(e, 1);
    });
    target_element.neighborhood().each(
        function (e) {
            var empDegree = 30;
            e.style('background-color', tinycolor(e.style('background-color')).darken(empDegree).toString());
            e.style('line-color', tinycolor(e.style('line-color')).darken(empDegree).toString());
            e.style('target-arrow-color', tinycolor(e.style('target-arrow-color')).darken(empDegree).toString());
        }
    );
    target_element.style('z-index', getMaxZIndex());
    target_element.style('width', Math.max(parseFloat(target_element.style('width')), nodeActiveSize));
    target_element.style('height', Math.max(parseFloat(target_element.style('height')), nodeActiveSize));
    target_element.style('font-size', Math.max(parseFloat(target_element.style('font-size')), nodeActiveFontSize));
}

function calculateRankRatioForNormalize(target_cy) {
    let ranks = [];
    target_cy.nodes().forEach(function (target) {
        const rank = pageRank.rank(target);
        ranks.push(rank);
    });
    let rankRatio = Math.max.apply(null, ranks) / 100;
    return rankRatio;
}

function setResetFocus(target_cy) {
    let maxSize = 0;
    let rankRatio = calculateRankRatioForNormalize(target_cy);
    target_cy.nodes().forEach(function (target) {
        target.style('background-color', nodeBGColor);
        var rank = pageRank.rank(target);
        var normalizedRank = rank / rankRatio;
        target.style('width', normalizedRank + nodeMinSize);
        target.style('height', normalizedRank + nodeMinSize);
        target.style('font-size', fontMaxSize * rank + fontMinSize);
        target.style('color', fontColor);
    });
    target_cy.edges().forEach(function (target) {
        target.style('line-color', edgeBGColor);
        target.style('target-arrow-color', edgeBGColor);
        target.style('width', edgeWidth);
        target.style('arrow-scale', arrowScale);
    });

}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function applyContextMenu(param, props) {
    cy.cxtmenu({
        selector: 'node',
        commands: [
            {
                content: '삭제',
                select: function (ele) {
                    if (is_login()) {
                        if (is_my_map()) {
                            if (window.confirm('really?')) {
                                _delete(`/api/v1/seomalmap/${param.map_id}/node/?node_id=${ele.id()}`, {}, (result) => {
                                    get(`/api/v1/seomalmap/${param.map_id}/`, {}, (json) => {
                                        props.set(json.data.elements, param.map_id)
                                    });
                                })
                            }
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog();
                    }

                }
            },
            {
                content: '부모 지식 생성',
                select: function (ele) {
                    console.log('create parent')
                    if (is_login()) {
                        if (is_my_map() || is_public_write_map()) {
                            props.open_create_node('PARENT', ele.id(), cy.viewport().pan());
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog()
                    }
                }
            },
            {
                content: '자식 지식 생성',
                select: function (ele) {
                    if (is_login()) {
                        if (is_my_map() || is_public_write_map()) {
                            props.open_create_node('CHILD', ele.id(), cy.viewport().pan());
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog();
                    }
                }
            },
            {
                content: '편집',
                select: function (ele) {
                    if (is_login()) {
                        if (is_my_map() || is_public_write_map()) {
                            props.open_edit_node(ele.id(), cy.viewport().pan());
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog();
                    }
                }
            },  
            {
                content: '부모 지식 연결',
                select: (ele) => {
                    if (is_login()) {
                        if (is_my_map() || is_public_write_map()) {
                            props.edge_connect_source(ele.id());
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog();
                    }
                }
            }
        ]
    });
    cy.cxtmenu({
        selector: 'edge',
        commands: [
            {
                content: 'Delete',
                select: function (ele) {
                    console.log('edge delete')
                    if (is_my_map()) {
                        if (window.confirm('really?')) {
                            _delete(`/api/v1/seomalmap/${param.map_id}/edge/?edge_id=${ele.id()}`, {}, (result) => {
                                get(`/api/v1/seomalmap/${param.map_id}/`, {}, (json) => {
                                    props.set(json.data.elements, param.map_id)
                                });
                            })
                        }
                    } else {
                        props.open_login_dialog();
                    }


                }
            }
        ]
    });
    cy.cxtmenu({
        selector: 'core',
        commands: [
            {
                content: 'Create',
                select: function (e) {
                    if (is_login()) {
                        if (is_my_map() || is_public_write_map()) {
                            props.open_create_node('ROOT', null, cy.viewport().pan());
                        } else {
                            alert('권한이 없습니다.');
                        }
                    } else {
                        props.open_login_dialog();
                    }
                }
            }
        ]
    });
}

export function init(tapOverHandler, tapOutHandler, tabNodeHandler, tabHandler, param, props) {
    return (_cy) => {
        cy = _cy;
        // 랜더링 될 때마다 실행되기 때문에 아래 코드가 중복실행 됩니다. 그래서 1회만 실행되는 코드를 임시로 넣어두었습니다.
        pageRank = cy.elements().pageRank();
        if (props.selected_node.id !== null) {
            var _selected = cy.$('#' + props.selected_node.id);
            setResetFocus(cy);
            setStyle(cy, {
                'background-color': dimColor,
                'line-color': dimColor,
                'target-arrow-color': dimColor,
                'target-arrow-shape': 'triangle',
                'color': dimColor
            });
            setFocus(cy, _selected, successorColor, predecessorsColor, edgeActiveWidth, arrowActiveScale);
            if (props.selected_node.is_need_animation) {
                cy.animate({
                    center: {eles: _selected},
                    zoom: 2
                }, {duration: 500})
            }
        } else {
            setResetFocus(cy);
        }
        if (cy.isInit)
            return;
        if(param.node_id){
            props.select('N'+param.node_id, true);
        }
        window.cy = cy;
        let zoom = localStorage.getItem(`map_viewport_zoom_${param.map_id}`);
        let panX = localStorage.getItem(`map_viewport_pan_x_${param.map_id}`);
        let panY = localStorage.getItem(`map_viewport_pan_y_${param.map_id}`);
        if(panX === null && panY === null){
            cy.center();
        }
        if (zoom) {
            cy.zoom(Number(zoom));
        }
        if (panX && panY) {
            cy.pan({x: Number(panX), y: Number(panY)});
        }
        // if(localStorage.getItem(`map_viewport_${param.map_id}`))
        // 초기화가 되었는지 확인하기 위한 코드입니다.
        cy.isInit = true;
        cy.on('tapdragover', 'node', tapOverHandler);
        cy.on('tapdragout', 'node', tapOutHandler);
        cy.on('tap', 'node', tabNodeHandler);
        cy.on('tap', tabHandler);
        var home = getParameterByName('i');
        if (home) {
            var _home = cy.$('#' + home);
            setResetFocus(cy);
            setFocus(cy, _home, successorColor, predecessorsColor, edgeActiveWidth, arrowActiveScale);

            cy.animate({
                center: {eles: _home}
            }, {duration: 1500})
        } else {
            setResetFocus(cy);
        }


        function setStyle(target_cy, style) {
            target_cy.nodes().forEach(function (target) {
                target.style(style);
            });
            target_cy.edges().forEach(function (target) {
                target.style(style);
            });

        }

        cy.on('tapstart tapend', 'node', (e) => {
            if (is_my_map()) {
                if (e.type === 'tapstart') {
                    let pos = e.target.position();
                    e.target._prevPosition = {x: pos.x, y: pos.y}
                }
                if (e.type === 'tapend') {
                    let prevPos = e.target._prevPosition;
                    let pos = e.target.position();
                    if (prevPos.x !== pos.x || prevPos.y !== pos.y) {
                        patch(`/api/v1/node/${e.target.id().substr(1)}/`, {
                            x: pos.x,
                            y: pos.y
                        }, () => {
                        })
                    }
                }
            }
            props.select(e.target.id(), false);
            props.close_tooltip();
        })
        cy.on('mouseout', 'node', function (e) {
            // debugger;
            // document.querySelector('#msg').value += (e.type)+"\n";
            // props.select(null);
        });
        cy.on('viewport', (e) => {
            localStorage.setItem(`map_viewport_zoom_${param.map_id}`, cy.zoom());
            localStorage.setItem(`map_viewport_pan_x_${param.map_id}`, cy.pan().x);
            localStorage.setItem(`map_viewport_pan_y_${param.map_id}`, cy.pan().y);
            props.close_tooltip();
        })

        cy.on('mouseover', 'node', function (e) {
            // props.select(e.target.id(), false);
            // props.close_tooltip();
        });

        if(isBeta()) {
            applyContextMenu(param, props);
        }

        cy.on("tapstart cxttapstart", function (e) {
            // 마지막으로 탭 한 position을 기록합니다.
            props.set_tab_position(e.position);
        });
    };
}

var pageRank = null;
var cy = null;
