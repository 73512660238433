import React from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Keyboard, Mood, ThumbUpAlt, Visibility,  Share} from '@material-ui/icons';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {post, get} from '../../lib/axios';
import {open_login_dialog, open_snackbar_dialog, set_user_marking} from "../../redux/map.action";
import copy from 'copy-to-clipboard';
import isBeta from "../../lib/beta";

const useStyles = makeStyles({
    tooltip: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        color: 'black',
        maxWidth: 400,
        fontSize: '1rem',
        border: '1px solid #dadde9',
        boxShadow: '10px 10px 49px -25px rgba(0,0,0,0.45)',
        padding: '1rem',
        paddingBottom: 11
    },
    popper: {
        zIndex: 'inherit !important'
    },
    btnGroup: {
        marginTop:'1rem'
    }
});

export default connect((state, ownProps) => {
    return {
        is_open: state.getIn(['ui', 'tooltip', 'is_open']),
        pos: state.getIn(['ui', 'tooltip', 'pos']),
        id: state.getIn(['ui', 'tooltip', 'id']),
        label: state.getIn(['ui', 'tooltip', 'label']),
        url: state.getIn(['ui', 'tooltip', 'url']),
        countMarking: state.getIn(['ui', 'tooltip', 'countMarking']),
        is_logined: state.getIn(['user', 'is_login']),
        selected_map_id: state.getIn(['map','selected_map_id']),
        marked:state.getIn(['user', 'marking'])
    }
}, (dispatch) => {
    return {
        open_login_dialog:()=>{
            dispatch(open_login_dialog())
        },
        open_snackbar:(msg, duration=6000)=>{
            dispatch(open_snackbar_dialog(msg, duration))
        }, 
        marking: (marked)=>{
            dispatch(set_user_marking(marked));
        },
        change_marking_number: (newMarked)=>{
            dispatch({type:'CHANGE_MARKING_NUMBER', newMarked:newMarked});
        }
    }
})((props) => {
    if (props.id === null) {
        return <div style={{display: 'none'}}></div>
    }
    const classes = useStyles();
    const h1Style = {fontSize: '1.4rem', lineHeight: '1.4rem', marginTop: '0.1rem', marginBottom:3};
    const markBtnIconFontSize = 'small';
    const handleMarking = (type, id, isMarked) => {
        // isMarked : 서버 통신 없이 marking 숫자를 변경하기 위한 코드
        if(props.is_logined){
            let requestUri = `/api/v1/node/${id}/`
            if (type === 'like') requestUri += 'toggle_like/';
            else if (type === 'saw') requestUri += 'toggle_saw/';
            else if (type === 'know') requestUri += 'toggle_know/';
            else if (type === 'can') requestUri += 'toggle_can/';
            else return;
            post(requestUri, {}, (res)=> {
                get('/api/v1/marking_by_member/', null, result=>{
                    props.marking(result.data);
                    props.change_marking_number(props.countMarking.saw+(isMarked ? -1 : 1));
                })
            })
        } else {
            props.open_login_dialog();
        }
    }

    function shareHandler() {
        const url = window.location.protocol+'//'+window.location.host+'/map/'+props.selected_map_id+'/'+props.id;
        copy(url);
        props.open_snackbar(url+'를 클립보드에 저장했습니다.', 3000)
    }
    function isMarked(){
        return props.marked.filter(e=>Number(e.node) === Number(props.id)).length > 0;
    }
    const _isMarked = isMarked();
    const MarkingButtonGroup = () => {
        const markBtn = isBeta() ? <Button startIcon={_isMarked ? <Visibility /> : <VisibilityOffIcon />} onClick={() => handleMarking('saw', props.id, _isMarked)}>봤어요 {props.countMarking.saw || 0}</Button> : null;
        return (
            <div style={{marginTop:2}}>
                <Button startIcon={<Share />} onClick={shareHandler}>공유</Button>
                {markBtn}
                {/*<ButtonGroup size="small" orientation="horizontal">*/}
                {/*    <Button startIcon={<ThumbUpAlt fontSize={markBtnIconFontSize}/>} onClick={() => props.handleMarking('like', props.id)}>좋아요 {props.countMarking.like || 0}</Button>*/}
                {/*    <Button startIcon={<Visibility fontSize={markBtnIconFontSize}/>} onClick={() => handleMarking('saw', props.id)}>봤어요 {props.countMarking.saw || 0}</Button>*/}
                {/*    <Button startIcon={<Mood fontSize={markBtnIconFontSize}/>} onClick={() => props.handleMarking('know', props.id)}>나 이거 알아 {props.countMarking.know || 0}</Button>*/}
                {/*    <Button startIcon={<Keyboard fontSize={markBtnIconFontSize}/>} onClick={() => props.handleMarking('can', props.id)}>나 이거 할줄 알아 {props.countMarking.can || 0}</Button>*/}
                {/*</ButtonGroup>*/}
            </div>
        );
    }
    return (<Tooltip classes={{
        tooltip: classes.tooltip,
        popper: classes.popper
    }} open={props.is_open} interactive title={
        <div>
            <h1 style={h1Style}>{props.label}</h1>
            <a href={props.url} target={"_blank"} rel={"noopener noreferrer"}>{props.url}</a>

            <MarkingButtonGroup/>

        </div>
    } style={{position: 'absolute', top: props.pos.y, left: props.pos.x}}
    >
        <div></div>
    </Tooltip>)


    /*
    todo:노드에 대한 상세 정보를 표현할 때 이 기능을 이용하기 위해서 주석으로 남겨두었습니다.
    get(`/api/v1/node/${props.id}/resource/`, {}, (json) => {
            props.setMeta({
                image:json.data.meta.image,
                title:json.data.meta.title,
                description:json.data.meta.description,
                is_loaded:true

            });
        });
     */

})
