const validateEmail = (email)=>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const checkErrorUsername = (username) => {
    if (username.length < 2) {
        return true;
    }
    return validateEmail(username);
}
const getHelperTextUserName = (username) => {
    if (!username.length) {
        return '아이디를 입력해 주세요';
    } else if (validateEmail(username)) {
        return '아이디는 이메일 형식이 아닙니다'
    }
    return ''
}

const checkErrorPassword = (password) => {
    if (password.length < 2) {
        return true
    }
};

export {
    validateEmail,
    checkErrorUsername,
    getHelperTextUserName,
    checkErrorPassword
}
