import React from "react";
import {NavMap} from '../NavHome';
import {connect} from "react-redux";
import {MenuMap} from "./Menu";
import CreateNode from "./CreateNode";
import Cytoscape from "./Cyto/Cyto";
import PreviewToolTip from "./PreviewToolTip";
import './map.css';
import EditNode from "./EditNode";
import MapSettingDialog from "./MapSettingDialog";

export default connect()((props) => {
    return (
        <div>
            <MenuMap></MenuMap>
            <PreviewToolTip></PreviewToolTip>
            <Cytoscape />
            <NavMap></NavMap>
            <CreateNode></CreateNode>
            <EditNode></EditNode>
            <MapSettingDialog></MapSettingDialog>
        </div>
    )
});

