import {connect} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import React, {useEffect} from "react";
import {get} from "../lib/axios";
import AddLocationIcon from '@material-ui/icons/AddLocation';

const MyMapList = connect(
    (state)=>{
        return {
            list:state.getIn(['MyMaps', 'list']),
        }
    },
    (dispatch)=>{
        return {
            setList:(data)=>dispatch({type:'SET_MYMAP_LIST', value:data}),
            setPublicList: (data) => dispatch({type: 'SET_PUBLIC_MAP_LIST', value:data}),
            create_map_open:function(){
                dispatch({type:'CREATE_MAP_OPEN'})
            },
            create_map_close:function(){
                dispatch({type:'CREATE_MAP_CLOSE'})
            }
        }
    }
)((props)=>{
    useEffect(() => {
        get('/api/v1/member/me/maps/', null, function (result) {
            props.setList(result.data.items);
            props.setPublicList(result.data.publicItems);
        })
    }, []);
    return <List
        subheader={
            <ListSubheader>
                나의 지도&nbsp;
                <Button
                    onClick={props.create_map_open}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{position:'absolute', right:0, margin:'5px'}}
                    endIcon={<AddLocationIcon />}
                >
                    add
                </Button>
            </ListSubheader>
        }
    >
        {props.list.map((item) => {
            return (
                <a key={item.id} href={`/map/${item.id}`}>
                    <ListItem key={item.id} button>
                        <ListItemText primary={item.name}/>
                    </ListItem>
                </a>
            )
        })}
    </List>;
});


const PublicMapList = connect(
    (state) => {
        return {
            is_login: state.getIn(['user', 'is_login']),
            list: state.getIn(['PublicMaps', 'list']),
        }
    },
    (dispatch)=>{
        return {
            setPublicList: (data) => dispatch({type: 'SET_PUBLIC_MAP_LIST', value:data}),
        }
    },
)((props) => {

    let Items = null;
    if (!props.is_login) {
        useEffect(() => {
            get('/api/v1/publicMaps/', null, function (result) {
                props.setPublicList(result.data);
            })
        }, []);
    }
    if (props.list) {
        Items = props.list.map(item => (
            <a key={item.id} href={`/map/${item.id}`}>
                <ListItem key={item.id} button>
                    <ListItemText primary={item.name}/>
                </ListItem>
            </a>
        ));
    }

    return (
        <List subheader={
            <ListSubheader>
                남의 지도&nbsp;
            </ListSubheader>
        }>
            {Items}
        </List>
    );
});

export const NavMap = connect(
    (state) => {
        return {
            map_drawer_is_open: state.getIn(['ui', 'map_drawer_is_open']),
            is_login: state.getIn(['user', 'is_login']),
        }
    },
    (dispatch) => {
        return {
            closeDrawer: () => {
                dispatch({type: 'CLOSE_MAP_DRAWER'})
            }
        }
    }
)((props) => {
    return (
        <Drawer open={props.map_drawer_is_open} onClose={() => props.closeDrawer()} style={{zIndex:10000}}>
            <div style={{width: 250}}>
                <div style={{padding:'0.8rem', fontSize:'1.6rem'}}>
                    <a style={{'textDecoration':'none', display:'block'}} href="/">Seomal</a>
                </div>
                <Divider/>
                {props.is_login ? <MyMapList/> : null}
                {props.is_login ? <Divider/> : null}
                <PublicMapList/>
            </div>
        </Drawer>
    )
});
