import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get, patch } from '../../lib/axios';

function EditNode({is_open, selected_map_id, close, set, type, target, position}) {
    const [label, setLabel] = useState('');
    const [content, setContent] = useState('https://');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        if(is_open && target){
            setIsLoading(true);
            get(`/api/v1/node/${target.replace('N', '')}/`, {}, function(res){
                setContent(res.data.member_resource.content);
                setLabel(res.data.label);
                setIsLoading(false);
            });
        }
    }, [is_open])
    const useStyles = makeStyles((theme) => ({
        progress: {
            display:'flex',
            width:'100%',
            height:'100%',
            alignItems:'center', 
            justifyContent:'center'
        },
        dialogContent:{
            minWidth:400,
            minHeight:100,
            height:1
        }
      }));
    const classes = useStyles();
    const handleSubmit = () => {
        let data = {
            label: label,
            node_label: label,
            url: content,
            content_type: 'URL',
            parent:null,
            child:null,
            x:position.x,
            y:position.y
        };

        const url = `/api/v1/seomalmap/${selected_map_id}/node/${target.replace('N', '')}/`;
        patch(url, data, function (result) {
            close();
            get(`/api/v1/seomalmap/${selected_map_id}/`, {}, (json) => {
                set(json.data.elements, Number(selected_map_id, {read_public: json.data.read_public, write_public: json.data.write_public}))
            });
            setLabel('');
            setContent('');
        });
    };

    const isValidContent = (content) => {
        const isURL = (content) => {
            const url_pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
            return url_pattern.test(content);
        };
        if (content.length > 0) {
            if (isURL(content)) {
                return [true, null];
            } else {
                return [false, 'URL 형식이 올바르지 않습니다. ex)https://seomal.com'];
            }
        } else {
            return [false, '값이 없습니다.']
        }
    };
    const contentValidResult = isValidContent(content);

    const isValidLabel = (label) => {
        if (label.length > 0) {
            return [true, null]
        } else {
            return [false, '값이 없습니다']
        }
    };
    const labelValidResult = isValidLabel(label);
    let dialogContent = (<>
        <div>
            <TextField label="지식 이름" error={!labelValidResult[0]} helperText={labelValidResult[1]} value={label} fullWidth onChange={(e) => setLabel(e.target.value)}></TextField>
        </div>
        <div>
            <TextField label="url" error={!contentValidResult[0]} value={content} onChange={(e) => setContent(e.target.value)} helperText={contentValidResult[1]} fullWidth></TextField>
        </div>
    </>);
    if(isLoading){
        dialogContent = <div className={classes.progress}>
            <CircularProgress></CircularProgress>
        </div>
    }
    return (
        <Dialog open={is_open} onClose={close} style={{zIndex:10000}}>
            <DialogTitle>지식 편집</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    취소
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    (state, ownProps) => {
        return {
            is_open: state.getIn(['ui', 'edit_node_is_open']),
            selected_map_id: state.getIn(['map', 'selected_map_id']),
            target:state.getIn(['ui','edit_node_target']),
            position:state.getIn(['ui', 'cursor_last_tab_position'])
        }
    },
    (dispatch) => {
        return {
            close: () => dispatch({type: 'CLOSE_EDIT_NODE'}),
            set: (value, map_id, permission) => {
                dispatch({type: 'SET_MAP', value: value, selected_map_id: map_id, permission: permission})
            }
        }
    }
)(EditNode)
