import Login from './Login';
import {connect} from "react-redux";
import {post} from "../lib/axios";
import {parseJwt, saveJwtToken} from '../lib/jwtToken';

export default connect((state)=>{
    return {
        is_open:state.getIn(['ui', 'login_dialog_is_open'])
    }
}, (dispatch)=>{
    return {
        close:()=>{
          dispatch({type:'CLOSE_LOGIN_DIALOG'})
        },
        submit:(username, password)=>{
            post('/api/login', {username, password}, (res) => {
                const jwtToken = res.data.token;
                const jwtBody = parseJwt(jwtToken);
                dispatch({type: 'CLOSE_LOGIN_DIALOG'});
                dispatch({type: 'SET_LOGIN_STATUS', value: true, username: jwtBody.username, userid: jwtBody.user_id, email: jwtBody.email});
                // dispatch({type: 'SET_LOGIN_STATUS', value: true});
                dispatch({type: 'JWT_TOKEN', value: jwtToken});
                saveJwtToken(jwtToken);
                window.location.reload();
            }, (err) => {
                // todo snackbar로 처리되면 좋을 것 같습니다.
                alert('아이디와 패스워드가 일치하지 않습니다.');
            }
            )
        }
    }
})(Login)
