import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import {get} from "../lib/axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function Profile(props) {
    const handleClose = () => {
        props.close();
    };
    return (
        <Dialog open={props.is_open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{zIndex:10000}}>
            <DialogTitle id="form-dialog-title">Profile</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        <ListItemText primary="E-Mail" secondary={props.profile.email}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Name" secondary={props.profile.username}></ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
