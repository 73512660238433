import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {get, post} from '../../lib/axios'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export function CreateMap({is_open, close, setList}) {
    const [name, setName] = useState('');
    const [readPublic, setReadPublic] = useState(true);
    const [writePublic, setWritePublic] = useState(true);

    const handleSubmit = () => {
        const data = {name:name}
        if(readPublic)
            data.read_public = 'on';
        if(writePublic)
            data.write_public = 'on';

        post('/api/v1/seomalmap/', data, function(result){
            close();
            get('/api/v1/member/me/maps/', null, function(result){
                setList(result.data.items);
            })
        }, function(){});
    }
    return (
        <Dialog open={is_open} onClose={close} style={{zIndex:10000}}>
            <DialogTitle>Create Map</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    지도 생성에 대한 도움말
                </DialogContentText>
                <TextField label="map name" value={name} onChange={(e)=>setName(e.target.value)}></TextField>
                <FormControlLabel
                    control={<Checkbox checked={readPublic} onChange={()=>setReadPublic(true)} value="read_public" />}
                    label="Read Public"
                />
                <FormControlLabel
                    control={<Checkbox checked={writePublic} onChange={()=>setWritePublic(true)} value="write_public" />}
                    label="Write Public"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}
