import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './redux/store';
import isBeta, {setBeta} from "./lib/beta";


if(window.location.pathname === '/beta'){
    setBeta();
    window.location.href = '/';
}
if(!isBeta()){
    if(!/\/map\/[0-9]+/.test(window.location.pathname)){
        window.location.href = '/map/1';
    }
}
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter><App/></BrowserRouter>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
