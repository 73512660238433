import React from 'react';
import {connect} from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default connect(
    state => ({open: state.getIn(['ui', 'fail_is_open'])}),
    dispatch => ({close:()=>dispatch({type:'CLOSE_FAIL'})})
)(props => {
    const handleClose = ()=> props.close();
    return (
        <Snackbar open={props.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          문제가 발생했습니다
        </Alert>
      </Snackbar>
    );
})