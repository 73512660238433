import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import React, {useRef} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {select} from "../../redux/map.action";
import Hotkeys from 'react-hot-keys';
import isBeta from "../../lib/beta";
import Settings from '@material-ui/icons/Settings';
import {isMapMember} from './lib/auth';


const useStyles = makeStyles({
    autoComplete:{
        padding:'9px',
        '& .MuiAutocomplete-inputRoot:before':{
            borderBottom:"none"
        },
    },
    container:{
        display:'grid',
        gridTemplateColumns:'1fr 48px',
        border:'1px solid #ccc',
        borderRadius:'5px',
        position:'absolute',
        top:'48px',
        width: '274px'
    },
    menuBtn:{
        position:'absolute',
        left:'-9px',
        width:'282px'
    }
})

const Search = (props) => {
    const classes = useStyles();
    const textField = useRef();
    var menuBtn;
    const settingBtn = ()=>{
        if(isMapMember()){
            return (<IconButton onClick={props.openMapSetting}>
                        <Settings></Settings>
                    </IconButton>)  
        } else {
            return null;
        }
    }
    
    if(isBeta()){
        menuBtn = (
            <div className={classes.menuBtn} style={{
                    display:'grid',
                    gridTemplateColumns:'50px 1fr 50px'
            }}>
                <IconButton onClick={props.openDrawer}>
                    <MenuIcon/>
                </IconButton>
                <div style={{padding:'15px 2px'}}>{props.name}</div>
                {settingBtn()}
            </div>)
    } else {
        menuBtn = (
            <div className={classes.menuBtn}>
                <div style={{padding:'15px'}}>{props.name}</div>
            </div>
        );
    }

    return (
        <div style={{position:'absolute', backgroundColor: 'rgba(255,255,255,0.9)', width: '281px', height: '108px'}}>
            {menuBtn}
            <div className={classes.container}>
                <div>
                    <Autocomplete
                        onChange={(event, target)=>{
                            if(!target)
                                return;
                            props.select(target.id, true);
                        }}
                        id="combo-box-demo"
                        options={props.elements.filter(e => e.data.id.indexOf('E') !== 0).map(e => ({title: e.data.label, url: e.data.url, id: e.data.id}))}
                        getOptionLabel={option => option.title}
                        style={{border: 'none', height: '100%'}}
                        size={"medium"}
                        margin={0}
                        className={classes.autoComplete}
                        renderInput={params => <TextField inputRef={textField} {...params} placeholder={"검색 (단축키 : /)"}/>}
                    />
                </div>
                <div>
                    <IconButton disabled onClick={props.openDrawer}>
                        <SearchIcon/>
                    </IconButton>
                </div>
                <Hotkeys keyName="/" onKeyUp={(KeyName, e, handle)=>{
                    textField.current.focus();
                }} />
            </div>
        </div>
    )
};

export default connect(
    state=>{
        return {
            elements:state.getIn(['map', 'elements']),
            name:state.getIn(['map', 'name']),
            members:state.getIn(['map','members']),
            user:state.getIn(['user'])
        }
    },
    dispatch=>({
        select:(id, is_need_animation)=>dispatch(select(id, is_need_animation)),
        openDrawer:()=>dispatch({type:'OPEN_MAP_DRAWER'}),
        openMapSetting:()=>dispatch({type:'OPEN_MAP_SETTING'})
    })
)(Search);
