import Signup from './Signup';
import {connect} from 'react-redux';
import {post} from '../lib/axios';

export default connect((state) => {
  return {
    is_open: state.getIn(['ui', 'signup_dialog_is_open'])
  }
}, (dispatch) => {
  const handleClose = () => {
    dispatch({type: 'CLOSE_SIGNUP_DIALOG'});
  };
  return {
    close: handleClose,
    submit: (username, email, password, password_confirm) => {
      if (password !== password_confirm) {
        alert('패스워드가 일치하지 않습니다.');
        return;
      }
      post('/api/signup', {username, email, password}, (res) => {
        if (res.data.result === 'ok') {
          alert(res.data.msg || '가입이 완료되었습니다.');
          handleClose();
          return;
        }
        alert('처리중 오류가 발생하였습니다.');
        return;

      }, (err) => {
        const res = err.response.data;
        if (res.msg && typeof(res.msg) === 'string') {
          alert(res.msg);
        } else if (res.msg) {
          alert(Object.keys(res.msg).map(k => res.msg[k]).join(','));
        } else {
          alert('처리중 오류가 발생하였습니다.');
        }
      })
    }
  }
})(Signup)
