import React, {useEffect} from 'react';
import './App.css';
import {Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import {getJwtToken, parseJwt, refreshToken} from './lib/jwtToken';
import {connect} from "react-redux";
import Login from "./components/Login.container";
import Signup from "./components/Signup.container";
import Profile from './components/Profile.contianer';
import Map from './components/map/Map';
import CreateMap from './components/MyMaps/CreateMap.container';
import Fail from "./components/feedback/Fail";
import NavAccount from "./components/NavAccount";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {close_snackbar_dialog} from "./redux/map.action";
import isBeta from "./lib/beta";
import {get} from './lib/axios';
import {set_user_marking} from './redux/map.action';
function fetchMarking(){
    
}
function App(props) {
    useEffect(() => {
        /*get('/profile/', null, (result) => {
            props.loginStatus(result.data.is_login, result.data.username, result.data.userid);
        });*/
        getJwtToken().then((jwtToken) => {
          if (jwtToken) {
            refreshToken(jwtToken).then((newJwtToken) => {
              if (newJwtToken) {                
                props.loginStatus(jwtToken);
                get('/api/v1/marking_by_member/', null, result=>{
                    props.marking(result.data);
                })
              }
            })
          }
        });
    }, []);
    return (
        <div className="App">
            <Switch>
                <Route path="/about">
                </Route>
                <Route path={["/map/:map_id/:node_id", "/map/:map_id"]}>
                    <Map></Map>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
            <Login/>
            <Signup/>
            <Profile/>
            {isBeta() ? <NavAccount/> : ''}
            <CreateMap/>
            <Fail/>
            <Snackbar open={props.snackbar_is_open} autoHideDuration={props.snackbar_auto_hide_duration}  onClose={()=>{
                props.close_snackbar();
            }}>
              <Alert severity="success">
                  {props.snackbar_message}
              </Alert>
            </Snackbar>
        </div>
    );
}

export default connect((state)=>{
        return {
            snackbar_is_open:state.getIn(['ui', 'snackbar', 'is_open']),
            snackbar_message:state.getIn(['ui', 'snackbar', 'message']),
            snackbar_auto_hide_duration:state.getIn(['ui', 'snackbar', 'auto_hide_duration'])
        }
    }, (dispatch) => {
    return {
        loginStatus: (jwtToken) => {
            const jwtBody = parseJwt(jwtToken);
            dispatch({type: 'SET_LOGIN_STATUS', value: true, username: jwtBody.username, userid: jwtBody.user_id, email: jwtBody.email});
            dispatch({type: 'JWT_TOKEN', value: jwtToken});
        },
        marking: (marked)=>{
            dispatch(set_user_marking(marked));
        }, 
        close_snackbar: () => {
            dispatch(close_snackbar_dialog())
        }
    }
})(App);
