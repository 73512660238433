import {connect} from "react-redux";
import {closeAccount, openAccount} from "../redux/global.action";
import React, {forwardRef} from "react";
import Fab from "@material-ui/core/Fab";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {removeJwtToken} from '../lib/jwtToken';

export default connect((state)=>{
    return {
        is_open:state.getIn(['ui','account_is_open']),
        is_login: state.getIn(['user', 'is_login'])
    }
}, (dispatch)=>{
    return {
        handleClose:()=>dispatch(closeAccount()),
        handleOpen:()=>dispatch(openAccount()),
        logout: () => {
            // get('/api/logout/', null, (json) => dispatch({type: 'SET_LOGIN_STATUS', value: false}))
            removeJwtToken();
            dispatch({type: 'SET_LOGIN_STATUS', value: false});
            dispatch({type: 'JWT_TOKEN', value: false});
            dispatch(closeAccount());
            window.location.reload();
        },
        login: () => {
            dispatch({type: 'OPEN_LOGIN_DIALOG'});
            dispatch(closeAccount());
            // window.location.href = '/login'
        },
        signup: () => {
            dispatch({type: 'OPEN_SIGNUP_DIALOG'});
            dispatch(closeAccount());
        },
        profile: () => {
            dispatch({type: 'OPEN_PROFILE_DIALOG'});
            dispatch(closeAccount());
        }
    }
})((props)=>{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const Profile = forwardRef(() => props.is_login ? <MenuItem onClick={props.profile}>Profile</MenuItem> : null);
    const MyAccount = forwardRef(() => props.is_login ? <MenuItem onClick={props.handleClose}>My account</MenuItem> : null);
    const ItemSignup = forwardRef(() => props.is_login ? null : <MenuItem onClick={props.signup}>Signup</MenuItem>);
    const Login = forwardRef(() => props.is_login ? null : <MenuItem onClick={props.login}>Login</MenuItem>);
    const Logout = forwardRef(() => props.is_login ? <MenuItem onClick={props.logout}>Logout</MenuItem> : null);

    return (
        <div style={{position:'absolute', 'right':'1rem', 'top':'1rem'}}>
            <Fab onClick={(e)=>{
                setAnchorEl(e.currentTarget);
                props.handleOpen();
            }}>
                <AccountCircleIcon />
            </Fab>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={props.is_open}
                onClose={props.handleClose}
              >
                <Profile/>
                <MyAccount/>
                <Login/>
                <Logout/>
              <ItemSignup/>
              </Menu>
        </div>
    );
});
