import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import CytoscapeComponent from "react-cytoscapejs";
import {get, post} from "../../../lib/axios";
import {useParams} from "react-router-dom";
import Cytoscape from "cytoscape";
import cxtmenu from "cytoscape-cxtmenu";
import COSEBilkent from "cytoscape-cose-bilkent";
import {
    close_tooltip,
    connect_abort,
    connect_source,
    open_create_node,
    open_login_dialog, open_tooltip,
    open_edit_node,
    select
} from "../../../redux/map.action";
import Connect from "../Connect";
import store from "../../../redux/store";
import {stylesheet} from "./style";
import {init} from "./init";

Cytoscape.use(cxtmenu);
Cytoscape.use(COSEBilkent);

const Cyto = (props) => {
    const [size, setSize] = useState([0, 0]);
    const param = useParams();
    const elements = props.elements;

    useEffect(() => {
        get(`/api/v1/seomalmap/${param.map_id}/`, null, json => {
            props.set(json.data.elements, param.map_id, json.data.object_members, {read_public: json.data.read_public, write_public: json.data.write_public}, json.data.name)
            localStorage.setItem(`last_visit_map`, JSON.stringify({id:param.map_id, name:json.data.name}));
        });

        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);    }, [param]);

    let timer = false;
    const tapOverHandler = (e) => {};
    const tapOutHandler = (e) => {};
    const tabNodeHandler = (e) => {
        // <Cytoscape>의 동작방법으로 인해서 elements가 변경될 때마다 콤퍼런트를 새롭게 다시 그리지 않고 있습니다.
        // tabHandler는 render가 실행될 때마다 생성되지만, elements의 값이 바뀌지 않았다면 Cytoscape 컴포넌트가 새롭게 그려지지 않습니다. 따라서 tabHandler도 신선한 상태가 아닙니다.
        // 그래서 props를 통해서 신선한 state의 값을 전달 받지 못하고 있습니다.
        // 이 문제를 해결하기 위해서 store를 직접 로딩해서 처리하고 있지만, 임시방편입니다. Cytoscape의 동작방법을 깊게 이해하고, React의 표준화된 구현방법에 따라서 Cytoscape를 사용하도록 변경해야 합니다.
        const is_connect_edge = store.getState().getIn(['ui', 'connect_edge', 'open']);
        if(is_connect_edge) {
            // 노드와 엣지를 연결하는 기능
            const source_id = store.getState().getIn(['ui', 'connect_edge', 'source']);
            return props.edge_connect_target(param.map_id, source_id, e.target.id());
        }
        let pos = e.target.renderedPosition();
        let node = e.target.data();
        props.open_tooltip(node.id.substring(1), node.url, node.label, pos);
    }
    const tabHandler = (e)=>{
        if(e.target.data().label === undefined){
            props.close_tooltip();
            props.select(null);
        }
    }
    // elements의 값이 없을 경우에는 값이 없는 것을 기준으로 Cytoscape의 레이아웃이 설정 되기 때문에 값이 없는 경우와 있는 경우를 구분해서 처리하고 있습니다.
    // 값이 없는 경우 return null을 하면 Cytoscape 자체가 로드되지 않기 때문에 노드 생성과 같은 기능을 사용할 수 없습니다. 그래서 구분하고 있습니다.
    // 지도를 변경하는 경우 과거에는 onpage이 방식으로 처리했지만, 여러가지 문제가 있어서 a 태그로 변경해서 페이지를 리로드 하고 있습니다.
    if (elements.length>0) {
        return (
            <div>
                <Connect></Connect>
                <CytoscapeComponent
                    className="cy"
                    elements={elements}
                    style={{width: size[0] + 'px', height: size[1] + 'px'}}
                    stylesheet={stylesheet}
                    // layout={layout}
                    cy={init(tapOverHandler, tapOutHandler, tabNodeHandler, tabHandler, param, props)}
                    zoom={1}
                    display="none"
                /></div>)
    } else {
        return <CytoscapeComponent
            className="cy"
            elements={elements}
            style={{width: size[0] + 'px', height: size[1] + 'px'}}
            stylesheet={stylesheet}
            // layout={layout}
            cy={init(tapOverHandler, tapOutHandler, tabNodeHandler, tabHandler, param, props)}/>;
    }
}
export default connect(
    (state) => {
        return {
            elements: state.getIn(['map', 'elements']),
            selected_node: state.getIn(['map', 'selected_node']),
            is_connecting_edge:state.getIn(['ui', 'connect_edge', 'open'])
        }
    },
    (dispatch) => {
        return {
            open_create_node: (connect_type, target, position) => dispatch(open_create_node(connect_type, target, position)),
            open_edit_node: (target, position) => dispatch(open_edit_node(target, position)),
            set: (data, map_id, members, permission, name) => {
                dispatch({type: 'SET_MAP', value: data, selected_map_id: map_id, members, permission, name:name})
            },
            edge_connect_source: (node) => {
                dispatch(connect_source(node))
            },
            edge_connect_target: (map_id, source, target) => {
                post(
                `/api/v1/seomalmap/${map_id}/edge/`,
                {label : null, source:source.replace('N', ''), target:target.replace('N','')},
                ()=>{
                    dispatch(connect_abort());
                    get(`/api/v1/seomalmap/${map_id}/`, null, json => {
                        dispatch({type: 'SET_MAP', value: json.data.elements, selected_map_id: map_id, permission: {read_public: json.data.read_public, write_public: json.data.write_public}});
                    });
                });
            },
            open_login_dialog:()=>{
                dispatch(open_login_dialog())
            },
            set_tab_position:(position)=>{
                dispatch({type:'TAB_POSITION', position:position});
            },
            select:(id, is_need_animation) => {
                dispatch(select(id, is_need_animation))
            },
            open_tooltip:(id, url, label, pos)=>{
                get(`/api/v1/node/${id}/`, {}, function(res){
                    dispatch(open_tooltip(id, url, label, pos, res.data.count_marking));
                })

            },
            close_tooltip:()=>{
                dispatch(close_tooltip());
            }
        }
    }
)(Cyto)
