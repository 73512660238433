import React, {useEffect} from "react";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {NavMap} from "./NavHome";
import "./Home.css";
import LastVisit from "./LastVisit";

function Home(props) {
    useEffect(()=>{
        const lastVisitStr = localStorage.getItem(`last_visit_map`);
        if(lastVisitStr){
            const lastVisit = JSON.parse(lastVisitStr);
            if(lastVisit.id && lastVisit.name){
                props.openLastVist(lastVisit.id, lastVisit.name);
            }
        }
    },[])
    return (
        <div style={{
            height:'100vh',
            // backgroundImage: 'url("http://source.unsplash.com/collection/9806662/1600*8000")',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat'
        }}>
            <IconButton aria-label="delete"  onClick={() => props.openDrawer()}>
                <MenuIcon/>
            </IconButton>
            <div className="home-bg" style={{
                height:'100vh',
                width:'100vw',
                display:'grid',
                gridTemplateRows: '1fr 1rem 1fr',
                position:'absolute',
                top:0,
                zIndex:-100
            }}>
                <div></div>
                <h1 style={{
                    margin:0,
                    padding:0,
                    height:'2rem',
                    textAlign:'center'
                }}>Seomal</h1>
                <div></div>
            </div>
            <NavMap></NavMap>
            <LastVisit></LastVisit>
        </div>
    )
}

export default connect((state) => {
    return {}
}, (dispatch) => {
    return {
        openDrawer: () => {
            dispatch({type: 'OPEN_MAP_DRAWER'})
        },
        openLastVist: (id, name)=> {
            dispatch({type:'OPEN_LAST_VISIT', id, name})
        }
    }
})(Home);