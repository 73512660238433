import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


import { get, patch } from '../../lib/axios';

function MapSettingDialog(props) {
    const {is_open, close, map_name} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(map_name);
    const [readPublic, setReadPublic] = useState(props.read_public);
    const [writePublic, setWritePublic] = useState(props.write_public);
    useEffect(()=>{
        if(is_open){
            setIsLoading(true);
            get(`/api/v1/seomalmap/${props.selected_map_id}/`, {}, (json) => {
                setIsLoading(false);
                props.change_map_setting(json.data.name, {read_public: json.data.read_public, write_public: json.data.write_public}); 
                setName(json.data.name);
                setReadPublic(json.data.read_public);
                setWritePublic(json.data.write_public);
            });
        }
    }, [is_open])
    const useStyles = makeStyles((theme) => ({
        progress: {
            display:'flex',
            width:'100%',
            height:'100%',
            alignItems:'center', 
            justifyContent:'center'
        },
        dialogContent:{
            minWidth:400,
            minHeight:300,
            height:1
        }
      }));
    const classes = useStyles();
    const handleSubmit = () => {
        setIsLoading(true);
        const data = {
            name:name,
            read_public:readPublic,
            write_public:writePublic
        }
        const url = `/api/v1/seomalmap/${props.selected_map_id}/`;
        patch(url, data, function (result) {
            get(`/api/v1/seomalmap/${props.selected_map_id}/`, {}, (json) => {
                props.change_map_setting(json.data.name, {read_public: json.data.read_public, write_public: json.data.write_public}); 
                close();
            });
        });
    };

    
    let dialogContent = (
        <form>
            <DialogContent>
                <TextField label="이름" value={name} onChange={e=>setName(e.target.value)}/>
            </DialogContent>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">읽기 권한</FormLabel>
                    <RadioGroup name="read_public" value={readPublic} onChange={event=>setReadPublic(event.target.value === 'true' ? true : false)}>
                        <FormControlLabel value={true} control={<Radio />} label="누구나 지도를 볼 수 있습니다" />
                        <FormControlLabel value={false} control={<Radio />} label="소유자만 지도를 볼 수 있습니다" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">쓰기 권한</FormLabel>
                    <RadioGroup name="write_public"  value={writePublic} onChange={event=>setWritePublic(event.target.value === 'true' ? true : false)}>
                        <FormControlLabel value={true} control={<Radio />} label="누구나 지도를 변경 할 수 있습니다" />
                        <FormControlLabel value={false} control={<Radio />} label="소유자만 지도를 변경 할 수 있습니다" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
        </form>
    );
    if(isLoading){
        dialogContent = <div className={classes.progress}>
            <CircularProgress></CircularProgress>
        </div>
    }
    return (
        <Dialog open={is_open} onClose={close} style={{zIndex:10000}}>
            <DialogTitle>{props.map_name} 설정 변경</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    취소
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    (state, ownProps) => {
        return {
            is_open: state.getIn(['ui', 'map_setting_is_open']),
            selected_map_id: state.getIn(['map', 'selected_map_id']),
            map_name:state.getIn(['map', 'name']),
            read_public:state.getIn(['map', 'permission', 'read_public']),
            write_public:state.getIn(['map', 'permission', 'write_public'])
        }
    },
    (dispatch) => {
        return {
            close: () => dispatch({type: 'CLOSE_MAP_SETTING'}),
            change_map_setting: (name, permission) => {
                dispatch({type: 'CHANGE_MAP_SETTING', name: name, permission: permission})
            }
        }
    }
)(MapSettingDialog)
