import {connect} from "react-redux";
import {CreateMap} from "./CreateMap";

export default connect(
    (state, ownProps) => {
        return {
            is_open: state.getIn(['MyMaps', 'CreateMap', 'is_open']),
            API_URL: state.getIn(['config', 'API_URL'])
        }
    },
    (dispatch) => {
        return {
            close:()=>dispatch({type:'CREATE_MAP_CLOSE'}),
            setList:(value)=>dispatch({type:'SET_MYMAP_LIST', value:value})
        }
    }
)(CreateMap)